import request from '@/router/axios'
// 查询license信息
export function getLicenseInfo() {
  return request({
    url: '/api/onlyone/authcenter/v1/license/info',
    method: 'get',
  })
}
// 更新license信息
export function updateLicense(data) {
  return request({
    url: '/api/onlyone/authcenter/v1/license/updateLicense',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
