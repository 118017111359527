<template>
  <div class="login-container">
    <div class="login-weaper animated bounceInDown">
      <div class="login-left">
        <img class="img" src="/img/logo.png" alt="" />
        <p class="title">{{ website.infoTitle }}</p>
        <p>©2020 v4.0.0</p>
      </div>
      <div class="login-border">
        <div class="login-main">
          <user-login v-if="activeName === 'user'"></user-login>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/styles/login.scss'
import userLogin from './userlogin'
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  components: {
    userLogin,
  },
  props: [],
  data() {
    return {
      activeName: 'user',
    }
  },
  computed: {
    ...mapGetters(['website']),
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>
