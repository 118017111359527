<template>
  <div class="license">
    <basic-container>
      <div class="license-info">
        <img src="img/license/license-card.svg" alt="" />
        <el-form label-position="left" label-width="184px" :model="licenseInfo">
          <div class="half-container">
            <el-form-item label="公司名称：">
              <span class="license-value" :title="licenseInfo.corporateName">
                {{ licenseInfo.corporateName }}
              </span>
            </el-form-item>
            <el-form-item label="机器识别码（MAC地址）：">
              <span class="license-value">
                {{ licenseInfo.macAddress }}
              </span>
            </el-form-item>
          </div>
          <div class="half-container">
            <el-form-item label="license生效时间：">
              <span class="license-value">
                {{ licenseInfo.issuedTime }}
              </span>
            </el-form-item>
            <el-form-item label="license到期时间：">
              <span class="license-value">
                {{ licenseInfo.expiryTime }}
              </span>
            </el-form-item>
          </div>
          <div class="half-container">
            <el-form-item label="产品类型：">
              <span class="license-value">
                {{ licenseInfo.productTypeName }}
              </span>
            </el-form-item>
            <el-form-item label="剩余使用时间：">
              <span class="license-value"> {{ licenseInfo.day }}天 </span>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </basic-container>
    <basic-container>
      <div class="upload-conatiner">
        <el-upload
          class="upload-demo"
          drag
          action=""
          :multiple="false"
          :show-file-list="false"
          :http-request="httpRequest"
        >
          <img
            src="img/license/license-upload.svg"
            alt=""
            @mouseenter="toggleUploadIcon(true)"
            @mouseleave="toggleUploadIcon(false)"
          />
          <img
            v-show="isShow"
            class="upload-icon"
            src="img/license/upload-icon.svg"
            alt=""
            @mouseenter="toggleUploadIcon(true)"
          />
          <div class="el-upload__text">如果需要更新license文件，请上传更新</div>
        </el-upload>
      </div>
      <div class="upload-conatiner">
        <el-upload
          action=""
          :on-change="handleChange"
          class="upload-demo"
          :multiple="false"
          :show-file-list="false"
          :http-request="httpRequest"
        >
          <el-button size="small" type="primary">更新license</el-button>
        </el-upload>
      </div>
    </basic-container>
  </div>
</template>

<script>
import { getLicenseInfo, updateLicense } from '@/api/admin/license.js'
export default {
  data() {
    return {
      licenseInfo: {},
      isShow: false,
    }
  },
  created() {
    this.getLicense()
  },
  methods: {
    getLicense() {
      getLicenseInfo().then(response => {
        this.licenseInfo = response.data.data
      })
    },
    toggleUploadIcon(val) {
      this.isShow = val
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3)
    },
    httpRequest(file) {
      const fm = new FormData()
      fm.append('upload', file.file)
      updateLicense(fm).then(response => {
        const { code, msg } = response.data
        this.$notify({
          type: code === 0 ? 'success' : 'warning',
          title: code === 0 ? '成功' : '警告',
          message: msg,
        })
        setTimeout(() => {
          this.getLicense()
        }, 5000)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.license {
  .license-info {
    img {
      padding: 32px 45px;
      float: left;
    }
    .half-container {
      display: flex;
      justify-content: space-between;
      .el-form-item {
        width: calc(50% - 32px);
        margin-left: 32px;
        .license-value {
          display: inline-block;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .upload-conatiner {
    margin: 32px auto;
    display: flex;
    justify-content: space-around;
    ::v-deep .el-upload-dragger {
      width: 100%;
      height: 100%;
      border: none;
      position: relative;
    }
    .upload-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
