<template>
  <div class="top-menu">
    <el-menu :default-active="activeIndex" mode="horizontal" text-color="#333">
      <el-menu-item
        v-for="(item, index) in items"
        :key="index"
        :index="item.parentId + ''"
        @click.native="openMenu(item)"
      >
        <template slot="title">
          <i :class="item.icon"></i>
          <span>{{ item.label }}</span>
        </template>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'TopMenu',
  data() {
    return {
      activeIndex: '0',
      items: [],
    }
  },
  computed: {
    ...mapGetters(['tagCurrent', 'menu']),
  },
  created() {},
  methods: {
    openMenu(item) {
      this.$store.dispatch('GetMenu', item.parentId).then(data => {
        if (data.length !== 0) {
          this.$router.$avueRouter.formatRoutes(data, true)
        }
        let itemActive
        const childItemActive = 0
        if (item.path) {
          itemActive = item
        } else {
          if (this.menu[childItemActive].length === 0) {
            itemActive = this.menu[childItemActive]
          } else {
            itemActive = this.menu[childItemActive].children[childItemActive]
          }
        }
        this.$router.push({
          path: this.$router.$avueRouter.getPath({
            name: itemActive.label,
            src: itemActive.path,
          }),
        })
      })
    },
  },
}
</script>
