<template>
  <div class="iframe-container">
    <iframe :src="$route.query.url" frameborder="0" class="type-link"></iframe>
  </div>
</template>

<script>
export default {
  created() {
    console.log(this.$route)
  },
}
</script>

<style lang="scss" scoped>
.iframe-container {
  padding: 16px 24px;
  border-radius: 10px;
  box-sizing: border-box;
  border-radius: 8px;
}
.type-link {
  height: 100vh;
  width: 100%;
}
</style>
